import "regenerator-runtime"; /* for async await transpile */
import "../styles/main.css";

const BoxHamburger = document.getElementById("Box-Hamburger");
const BtnHamburger = document.getElementById("Hamburger-menu");
const BoxNavbarLink = document.getElementById("navbar-nav");
const NavbarLink = document.querySelectorAll("#link");

BtnHamburger.onclick = () => {
	BoxHamburger.classList.toggle("active");
	BoxNavbarLink.classList.toggle("active");
};
document.addEventListener("click", (e) => {
	if (!BoxNavbarLink.contains(e.target) && !BtnHamburger.contains(e.target)) BoxHamburger.classList.remove("active");
	e.stopPropagation();
});
document.addEventListener("click", (e) => {
	if (!BoxNavbarLink.contains(e.target) && !BtnHamburger.contains(e.target)) BoxNavbarLink.classList.remove("active");
	e.stopPropagation();
});

NavbarLink.forEach((link) => {
	link.addEventListener("click", (e) => {
		BoxHamburger.classList.remove("active");
		BoxNavbarLink.classList.remove("active");
	});
});

// NavbarLink.forEach((link) => {
//   link.addEventListener('click', (e) => {
//     e.preventDefault();
//     NavbarLink.forEach((navLink) => {
//       navLink.classList.remove('active');
//     });
//     link.classList.add('active');
//     BoxHamburger.classList.remove('active');
//     BoxNavbarLink.classList.remove('active');
//     const targetId = link.getAttribute('href').substring(1);
//     const targetElement = document.getElementById(targetId);
//     if (targetElement) {
//       targetElement.scrollIntoView({
//         behavior: 'smooth',
//         block: 'start',
//       });
//     }
//     if (targetElement) {
//       const rect = targetElement.getBoundingClientRect();
//       if (rect.top <= 50 && rect.bottom >= 50) {
//         NavbarLink.forEach((navLink) => {
//           navLink.classList.remove('active');
//         });
//         link.classList.add('active');
//       }
//     }
//   });
// });
